<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Dokter'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      v-if="data != null"
                      :src="data.photo"
                      @load="onImageLoad()"
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col"
                v-if="imgLoaded"
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Email</strong></td>
                    <td>{{ data.email }}</td>
                  </tr>
                  <tr>
                    <td><strong>Kartu ID</strong></td>
                    <td>{{ data.id_card_number }}</td>
                  </tr>
                  <tr>
                    <td><strong>SIP</strong></td>
                    <td>{{ data.sip_number }}</td>
                  </tr>
                  <tr>
                    <td><strong>Masa Berlaku SIP</strong></td>
                    <td>s/d {{ new Date(data.sip_exp).toLocaleString('id-ID', { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' }) }}</td>                    
                  </tr>
                  <tr>
                    <td><strong>Poliklinik</strong></td>
                    <td>{{ data.polyclinic_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Telepon</strong></td>
                    <td>{{ data.phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Handphone</strong></td>
                    <td>{{ data.mobile_phone }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alamat</strong></td>
                    <td>{{ data.address }}</td>
                  </tr>
                </table>
                <button
                  @click="$router.push({path: '/doctor/edit/' + data.id})"
                  class="btn mx-1 btn-success"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data: null,
      // Other
      imgLoaded: false,
      // access management
      manipulateBtn:false,
    }
  },

  components: {
    Card,
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('doctors/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/doctors/list')
      }
    },

    async get() {
      this.data = await module.get('doctors/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/doctors/list')
      }
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "2002"){
        this.manipulateBtn = true
      }
    }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter", route: "" },
      { title: "Daftar Dokter", route: "/doctor/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    // access
    this.setActiveMenu()
  },

}

</script>